import DOMPurify from 'dompurify';
import { useMemo } from 'react';
import { matchPath } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import routes from 'router/routes';

export const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const getLowestValue = (data, key) => {
  const values = data.map((obj) => obj[key]).filter((value) => value !== null);
  return values.reduce((min, value) => Math.min(min, value), Infinity);
};

export const getLowestPlanTerms = (plans) => {
  const minimum_term_in_days = plans.map((plan) => {
    return plan?.min_terms_years * 365 + plan?.min_terms_months * 30 + plan?.min_terms_days;
  });

  const filtered_days = minimum_term_in_days.filter((num) => num !== 0);

  return filtered_days.length ? Math.min(...filtered_days) : 0;
};

export const truncateText = (text, maxWords) => {
  if (text.length > maxWords) {
    return text.slice(0, maxWords) + '...';
  }
  return text;
};

export const checkRoute = (path) => {
  return useMemo(() => {
    const res = routes.filter((loc) => {
      return matchPath(loc.path, path);
    });

    const route = res.find((el) => el.path !== '*');
    if (route) {
      return res.find((el) => el.path !== '*').isPrivate || false;
    }

    return false;
  }, [path]);
};

export const checkUrl = (url) => {
  if (!url?.startsWith('https') && !url?.startsWith('http')) {
    return 'http://' + url;
  }
  return url;
};

export const getLowestPlan = (plans) => {
  const lowestValue = getLowestValue(plans, 'price');
  const minimum = plans?.filter((m) => m.price == lowestValue);

  if (minimum?.length > 1) {
    const annualPlan = minimum?.filter((m) => m.plan_type == 2);
    const monthlyPlan = minimum?.filter((m) => m.plan_type == 1);

    if (annualPlan?.length >= 1) {
      return annualPlan[0];
    } else if (monthlyPlan?.length >= 1) {
      return monthlyPlan[0];
    }
  }

  return minimum[0];
};

export const setLowestPlanType = (plan) => {
  let type = plan?.plan_type;

  switch (type) {
    case 1:
      type = '/月額';
      break;
    case 2:
      type = '/年額';
      break;
    case 3:
      type = `/${plan.plan_type_description}`;
      break;
    default:
      type = '';
      break;
  }

  return type;
};

export const isMobile = () => {
  return useMediaQuery('(max-width:600px)');
};

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const authPages = ['login', 'forgot-password', 'signup'];

export const scrollableItem = {
  overflowY: 'auto',
  '::-webkit-scrollbar': {
    width: '5px',
  },
  '::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '::-webkit-scrollbar-thumb': {
    background: '#888',
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
};
export const htmldecode = (text) => {
  var txt = document.createElement('textarea');
  txt.innerHTML = text;
  return txt.value;
};

export const linkifyText = (inputText) => {
  // Regular expression to detect URLs
  const urlPattern = /(https?:\/\/|www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}([/?#][^\s]*)?/g;

  // Replace detected URLs with anchor tags
  const linkedText = inputText?.replace(urlPattern, (url) => {
    if (!/^https?:\/\//i.test(url)) {
      url = 'https://' + url;
    }

    return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
  });

  // encode and return text
  return linkedText;
};

export const sanitizedContent = (content) => {
  return DOMPurify.sanitize(content);
};

export const setLocalStorageItem = (key, value) => {
  return localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorageItem = (key) => {
  const item = localStorage.getItem(key);

  return item ? JSON.parse(item) : null;
};

export const removeLocalStorageItem = (key) => {
  return localStorage.removeItem(key);
};
