import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';

const useGTMPageView = () => {
  const location = useLocation();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        pagePath: location.pathname,
      },
    });
  }, [location]);
};

export default useGTMPageView;
