import useGTMPageView from 'hooks/useGTMPageView';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { removeLocalStorageItem } from 'utils/helper';
import Router from './router';
import theme from './theme';

function App() {
  useGTMPageView();
  const location = useLocation();
  const currentPage = location.pathname;
  const categoriesAndSearch = /^\/(search|service\/details\/[^/]+|service\/[^/]+\/reviews)$/;

  useEffect(() => {
    if (!categoriesAndSearch.test(currentPage)) {
      removeLocalStorageItem('search_plan_filter');
      removeLocalStorageItem('search_selected_subcategory');
      removeLocalStorageItem('search_searched_keyword');
      removeLocalStorageItem('search_current_page');
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  );
}

export default App;
